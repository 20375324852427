<template>
   <v-container fluid>
       <v-row justify="center">
           <v-col></v-col>
           <v-col cols="12" class="text-center">
               <h1>MI HUELLA HÍDRICA</h1>
           </v-col>
           <v-col cols="12" sm="8" class="pb-0">
                <p>Mi Huella Hídrica es el volumen de agua dulce consumida, utilizada y contaminada de manera directa por las actividades diarias que realizamos, más el volumen de agua utilizada en la producción de los bienes y servicios que consumimos (huella hídrica virtual). Estos cálculos fueron desarrollados por investigadores de la Unesco-IHE para evaluar su única huella hídrica. Los cálculos se basan en las necesidades de agua por unidad de producto.
                La Huella Hídrica está compuesta por dos dimensiones: la Huella Hídrica Directa y la Huella Hídrica Indirecta, y a su vez cada una contempla tres tipos de huella: Azul, Gris y Verde:
                </p>

                <p>
                    <v-icon>mdi-check</v-icon>
                    <strong>Azul:</strong> volumen de agua que se evapora, incorpora o se pierde de la cuenca.
                </p>

                <p>
                    <v-icon>mdi-check</v-icon>
                    <strong>Gris:</strong> volumen de agua necesario para asimilar la carga contaminante del agua residual generada, según parámetros aceptables de la normativa local.
                </p>

                <p>
                    <v-icon>mdi-check</v-icon>
                    <strong>Verde:</strong> volumen de agua consumida en productos agrícolas.
                </p>

                 
           </v-col>
           <v-col cols="12" class="text-center">
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/municipio"
                    >
                    Iniciar <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
           </v-col>
       </v-row>
   </v-container>
</template>

<script>

export default {

    data() {
        return {
            
        }
    },

    mounted() {
        this.$store.state.progress = 0
    },

    computed: {
       
    },

    methods: {
       
    },
}
</script>